<template>
  <div>
    <page-header name="Processamentos em andamento"/>
    <b-card>
      <Grid
        :fields="fields"
        :items="items"
        label="Sorteios"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getHistory"
      >
        <template
          #cell(created_at)="data"
        >
          <b>Promoção:</b> {{ data.item.promotion.name }}<br>
          <b>Cliente:</b> {{ data.item.promotion.company.name }}<br>
          <b>Data:</b> {{ data.item.created_at }}<br>
        </template>
        <template
          #cell(user)="data"
        >
          {{ data.item.user ? data.item.user.name : 'API' }}
        </template>
        <template
          #cell(total_numbers)="data"
          style="width: 30%"
        >
          {{ new Intl.NumberFormat('pt-BR').format(data.item.total_numbers) }}
        </template>
        <template
          #cell(total_lines)="data"
          style="width: 30%"
        >
          {{ new Intl.NumberFormat('pt-BR').format(data.item.total_lines) }}
        </template>
        <template
          #cell(person_success)="data"
          style="width: 30%"
        >
          {{ new Intl.NumberFormat('pt-BR').format(data.item.person_success) }}
        </template>
        <template
          #cell(person_error)="data"
          style="width: 30%"
        >
          {{ new Intl.NumberFormat('pt-BR').format(data.item.person_error) }}
        </template>
      </Grid>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'GlobalPromotionImportList',
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: 'created_at',
          label: 'Data',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'running_time',
          label: 'Tempo',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'original_filename',
          label: 'Arquivo',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'user',
          label: 'Enviado por',
          class: 'w-20',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'total_lines',
          label: 'Linhas',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'person_success',
          label: 'Sucessos',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'person_error',
          label: 'Erros',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'total_numbers',
          label: 'Números',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      items: [],
      interval: null,
    }
  },
  watch: {
    show(value) {
      this.showModal = value
      if (value) {
        this.getHistory()
      }
    },
  },
  mounted() {
    this.getHistory()
    this.interval = setInterval(() => {
      this.getHistory()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    async getHistory(page) {
      if (this.promotionUuid === '') {
        return
      }
      const route = 'dashboards/promotions/current-imports'
      this.items = await this.$search.get(
        route,
        page,
        this.search,
        '',
      )
    },

    downloadFile(file) {
      window.open(file.url_download_log, '_blank')
    },
  },
}
</script>

<style scoped>

</style>
